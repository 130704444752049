const hasValue = (value) => value !== void 0 && value !== null;
const getDataContent = (data, defaultData) => {
  if (hasValue(data)) {
    if (hasValue(data.data)) {
      return hasValue(data.data.content) ? data.data.content : data.data;
    }
    return hasValue(data.content) ? data.content : data;
  }
  return defaultData;
};
const getArrayDataContent = (data) => {
  const arrayData = getDataContent(data, []);
  return Array.isArray(arrayData) ? arrayData : [];
};
export {
  getArrayDataContent,
  getDataContent
};
