import { jsx } from "react/jsx-runtime";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { usePersistedState } from "../hooks";
import { merge, uniq } from "lodash";
import CudaMessages from "./CudaMessages";
const I18nContext = createContext({
  activeLanguage: "en",
  availableLanguages: ["en"]
});
const I18nextProvider = ({ messages, children }) => {
  const initialMessages = merge(CudaMessages, messages);
  i18next.use(LanguageDetector).use(initReactI18next).init({
    resources: initialMessages,
    fallbackLng: "en",
    keySeparator: ".",
    nsSeparator: "|",
    interpolation: {
      escapeValue: false
    },
    // In case top-level keys are provided to translate (e.g. "cuda", or "cuda.actions") instead of a full specific identifier, just return the key.
    returnedObjectHandler: (key) => key
  });
  const initialLanguage = Object.keys(initialMessages).find((language) => i18next.language.startsWith(language)) || "en";
  const [activeLanguage = initialLanguage, setActiveLanguage] = usePersistedState("language", initialLanguage);
  const [availableLanguages, setAvailableLanguages] = useState(Object.keys(initialMessages).sort());
  const addToMessages = useCallback((newMessages) => {
    if (!newMessages)
      return;
    Object.keys(newMessages).forEach((language) => {
      Object.keys(newMessages[language]).forEach((namespace) => {
        i18next.addResourceBundle(language, namespace, newMessages[language][namespace], true, true);
      });
    });
    setAvailableLanguages((currentLanguages) => uniq([...currentLanguages, ...Object.keys(newMessages)]).sort());
  }, []);
  useEffect(() => {
    i18next.changeLanguage(activeLanguage);
  }, [activeLanguage]);
  return /* @__PURE__ */ jsx(I18nContext.Provider, { value: { i18next, addToMessages, activeLanguage, setActiveLanguage, availableLanguages }, children });
};
const useI18next = () => useContext(I18nContext);
export {
  I18nContext,
  I18nextProvider,
  useI18next
};
