const WINDOWS = "windows";
const MACOS = "macos";
const IOS = "ios";
const ANDROID = "android";
const LINUX = "linux";
const browserIsIE = () => {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes("MSIE") || userAgent.includes("Trident");
};
const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  if (platform.includes("Mac")) {
    return MACOS;
  }
  if (platform.includes("iP") || platform.includes("Pike")) {
    return IOS;
  }
  if (platform.includes("Win")) {
    return WINDOWS;
  }
  if (userAgent.includes("Android")) {
    return ANDROID;
  }
  if (userAgent.includes("Linux")) {
    return LINUX;
  }
  return null;
};
export {
  ANDROID,
  IOS,
  LINUX,
  MACOS,
  WINDOWS,
  browserIsIE,
  getOS
};
