var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { get, isEqual, isEqualWith } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
const isChild = (item) => get(item, "$$typeof", "").toString().startsWith("Symbol");
const simplifyChild = (_a) => {
  var { key, type, props: _b = {} } = _a, _c = _b, { children } = _c, props = __objRest(_c, ["children"]);
  return { key, type, props: __spreadValues({ children: children && React.Children.toArray(children) }, props) };
};
const customChildIsEqual = (valueA, valueB) => {
  const resolveA = isChild(valueA) && simplifyChild(valueA);
  const resolveB = isChild(valueB) && simplifyChild(valueB);
  return resolveA || resolveB ? isEqualWith(resolveA || valueA, resolveB || valueB, customChildIsEqual) : void 0;
};
const useDeepCompareMemoize = (value) => {
  const ref = useRef(value);
  if (!isEqualWith(value, ref.current, customChildIsEqual)) {
    ref.current = value;
  }
  return ref.current;
};
const useDeepCompareMemo = (callback, dependencies) => useMemo(callback, useDeepCompareMemoize(dependencies));
const useDeepCompareCallback = (callback, dependencies) => {
  const refDependencies = useRef(dependencies);
  const refCallback = useRef(callback);
  if (!isEqualWith(refDependencies.current, dependencies, customChildIsEqual)) {
    refDependencies.current = dependencies;
    refCallback.current = callback;
  }
  return refCallback.current;
};
const useDeepCompareEffect = (callback, dependencies, runOnMount = true) => {
  const allowRun = useRef(runOnMount);
  useEffect(() => {
    if (allowRun.current) {
      return callback();
    }
    allowRun.current = true;
  }, useDeepCompareMemoize(dependencies));
};
const usePrevious = (value) => {
  const ref = useRef();
  const oldValue = ref.current;
  ref.current = value;
  return oldValue;
};
const usePersistedState = (uniqueId, defaultValue) => {
  const { localStorage } = window;
  const initialSavedValue = useMemo(() => {
    const savedValue = localStorage.getItem(uniqueId);
    try {
      return savedValue ? JSON.parse(savedValue) : void 0;
    } catch (e) {
      return void 0;
    }
  }, []);
  const [value, setLiveValue] = useState(initialSavedValue === void 0 ? defaultValue : initialSavedValue);
  const setValue = (newValue) => {
    if (!isEqual(newValue, value)) {
      localStorage.setItem(uniqueId, JSON.stringify(newValue));
      setLiveValue(newValue);
    }
  };
  return [value, setValue];
};
export {
  customChildIsEqual,
  isChild,
  simplifyChild,
  useDeepCompareCallback,
  useDeepCompareEffect,
  useDeepCompareMemo,
  useDeepCompareMemoize,
  usePersistedState,
  usePrevious
};
